<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-02-10 17:07:45
 * @LastEditTime : 2022-07-20 16:57:24
 * @LastEditors  : BigBigger
-->
<template>
	<div class="through-pay">
		<div class="title">
			<span>缴费方式</span>
		</div>
		<div class="info-content">
			<van-cell style="padding: 0;"></van-cell>
      <van-radio-group v-model="throughPay" @change="value => $store.dispatch('setThroughPay', value)">
        <div class="infoList">
          <van-radio checked-color="#FF3F47" :name="1"></van-radio>
          <span class="sp">{{THROUGH_PAY[1]}}</span>
        </div>
				<div class="infoList">
          <van-radio checked-color="#FF3F47" :name="0"></van-radio>
          <span class="sp">{{THROUGH_PAY[0]}}</span>
        </div>
      </van-radio-group>
			<div class="pay-bottom color-red">说明：{{ throughPay === 1 ? courseInfo.msg1 : courseInfo.msg2 }}</div>
		</div>
	</div>
</template>

<script>
import { THROUGH_PAY } from '@/config/config';

export default {
	name: 'ThroughPay',
	data() {
		return {
			THROUGH_PAY,
			throughPay: 0,
			courseInfo: {}
		};
	},
	mounted() {
		this.throughPay = this.$store.state.orderInfo.throughPay || 0;
		this.courseInfo = this.$store.state.orderInfo.loCourseInfo || {};
	},
};
</script>

<style lang="less" scoped>
.through-pay {
	// width:94%;
	padding: 0 3%;
	background: #fff;
	margin-top: 0.25rem;
	padding-bottom: 0.2rem;
}
.through-pay .title {
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	line-height: 0.65rem;
	padding: 0.2rem 0;
	border-bottom: 1px #f2f2f5 solid;
	display: flex;
	justify-content: space-between;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.8rem;
  text-align: left

}
.info-content .infoList .van-radio{
  margin-right:0.3rem;
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .sp{
  flex-grow: 0;
  flex-shrink: 0;
}
.info-content .infoList .money{
  color:#FF3F47;
  flex-grow:2

}
.info-content .infoList .money i{
  font-size: 12px;
  font-style: normal;
}
  .pay-bottom {
		margin-top: 0.2rem;
    font-size: 14px;
  }
</style>
