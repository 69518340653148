<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-02-10 17:07:45
 * @LastEditTime : 2022-02-14 17:06:11
 * @LastEditors  : BigBigger
-->
<template>
	<div class="discount">
		<div class="title">
			<span>优惠信息 （老学员优惠）</span>
		</div>
		<div class="infoList">
			<span class="in-label in-label-req">是否老学员</span>
			<van-radio-group
				v-model="loDiscountInfo.studentsPreferential"
				direction="horizontal"
			>
				<van-radio checked-color="#FF3F47" :name="1"
					>是(参与老学员优惠)</van-radio
				>
				<van-radio checked-color="#FF3F47" :name="2">否</van-radio>
			</van-radio-group>
		</div>
    <!-- ios 刷新机制离谱，增加空白组件 -->
    <van-cell style="padding: 0;"></van-cell>
		<div class="infoList" style="flex-wrap: wrap;" v-if="loDiscountInfo.studentsPreferential === 1">
			<span
				class="in-label"
				:class="{
					'in-label-req': loDiscountInfo.studentsPreferential === 1,
				}"
				style="width: 100%;line-height: 2;margin-top: 0.2rem;"
				>老学员证明
				（老学员需提供以往学员证照片或课程支付成功订单详情截图）</span
			>
			<v-upload v-model="loDiscountInfo.field01" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'discount',
	data() {
		return {
			loDiscountInfo: {},
		};
	},
	mounted() {
		this.loDiscountInfo = this.$store.state.orderInfo.loDiscountInfo || {};
	},
	watch: {
		loDiscountInfo: {
			handler() {
				this.updateInfo();
			},
			deep: true,
		},
	},
	methods: {
		updateInfo() {
			this.$store.dispatch('setOrderInfo', {
				...this.$store.state.orderInfo,
				loDiscountInfo: this.loDiscountInfo,
			});
		},
	},
};
</script>

<style lang="less" scoped>
.discount {
	// width:94%;
	padding: 0 3%;
	background: #fff;
	margin-top: 0.25rem;
	padding-bottom: 0.8rem;
}
.discount .title {
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	line-height: 0.65rem;
	padding: 0.2rem 0;
	border-bottom: 1px #f2f2f5 solid;
	display: flex;
	justify-content: space-between;
}
.info-box {
	//   width:94%;
	padding: 0 3%;
	background: #fff;
	// margin-bottom: 0.25rem;
}
.infoList {
	display: flex;
	font-size: 14px;
	color: #5b6b73;
	line-height: 1rem;
	text-align: left;
	// margin:0.15rem 0;
	border-bottom: 1px #f2f2f5 solid;
	display: flex;
	align-items: center;
}
.infoList span {
	width: 30%;
}
.infoList input[type='text'] {
	color: #333;
}
.infoList .info-right input[type='radio'] {
	width: 0.22rem;
	height: 0.22rem;
	margin-right: 0.05rem;
}
.infoList .info-right input[type='radio']:before {
	width: 0.22rem;
	height: 0.22rem;
}
input[type='radio']:checked:after {
	top: 0.08rem;
	left: 0.05rem;
}

.in-label {
	padding-left: 0.1rem;
}
.in-label-req::before {
	display: inline-block;
	content: '*';
	color: #ff3f47;
}
</style>
