<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-09-20 11:13:16
 * @LastEditors  : BigBigger
-->
<template>
	<div>
		<div class="signup-box">
			<CourseInfo :orderInfo="orderInfo" />
			<AddressInfo
				ref="addressInfo"
				v-if="courseInfo && courseInfo.hasShopAddress"
			/>
			<JobInfo v-if="courseInfo && courseInfo.interviewFlag === 1" />

			<div
				class="personInfo"
				@click="editInfo"
				v-if="courseInfo && courseInfo.whetherDisplayPersonalInfo === 1"
			>
				<div class="title">
					<span>个人信息：</span>
					<span class="red" v-if="!getLoUserInfo"
						>请填写报名信息</span
					>
					<span class="red" v-if="getLoUserInfo">已填写</span>
					<span class="arrow">
						<img src="@/assets/images/h5-icon/arrow-right.png"
					/></span>
				</div>
			</div>
			<Discount
				v-if="
					courseInfo &&
						courseInfo.interviewFlag === 3 &&
						courseInfo.oldStudentsPreferential === 1
				"
			/>
			<ThroughPay v-if="courseInfo && courseInfo.throughPay === 1" />
			<PayType ref="paytype" v-if="courseInfo" />
			<div class="reviewbox" v-if="courseInfo">
				<p v-if="courseInfo.interviewFlag === 1">
					请先提交审核，通过后缴费！
				</p>
				<p class="reviewBtn" @click="formCheck">
					提交{{ courseInfo.interviewFlag === 1 ? '审核' : '订单' }}
				</p>
			</div>
			<!-- 信息确认弹窗 -->
			<template v-if="showInfoDialog">
				<InfoTable
					@onClose="infoOnClose"
					v-if="orderInfo && showInfoDialog"
					:orderInfo="orderInfo"
					:addressInfo="addressInfo"
					:payType="payType"
				/>
			</template>

			<!-- <van-overlay :show="showInfoDialog" @click="showInfoDialog = false">
      <InfoTable @onClose="infoOnClose" v-if="orderInfo && showInfoDialog" :orderInfo="orderInfo" :addressInfo="addressInfo" :payType="payType" />
    </van-overlay> -->
		</div>
	</div>
</template>

<script>
import { createOrder } from '@/api/api';
import CourseInfo from '@/components/courseGroup/signup/courseInfo.vue';
import AddressInfo from '@/components/courseGroup/signup/AddressInfo.vue';
import JobInfo from '@/components/courseGroup/signup/JobInfo.vue';
import PayType from '@/components/courseGroup/signup/PayType.vue';
import InfoTable from '@/components/courseGroup/signup/InfoTable';
import Discount from '@/components/courseGroup/signup/discount';
import ThroughPay from '@/components/courseGroup/signup/throughPay';
import qs from 'qs';
import { mapState } from 'vuex';
export default {
	name: 'SignUp',
	middleware: 'accountVerification',
	async asyncData(context) {
		let nowStep = +context.query.step || 1;
		let returnObj = {};
		return returnObj;
	},
	data() {
		return {
			// orderInfo: null,
			// courseInfo: null,
			// userInfo: null,
			// jboInfo: null,
			showInfoDialog: false,
			addressInfo: null,
			payType: null,
		};
	},
	computed: {
		...mapState({
			orderInfo: (state) => state.orderInfo,
			courseInfo: (state) =>
				(state.orderInfo && state.orderInfo.loCourseInfo) || null,
			userInfo: (state) =>
				(state.orderInfo && state.orderInfo.loUserInfo) || null,
			jboInfo: (state) =>
				(state.orderInfo && state.orderInfo.loJboInfo) || null,
			flightIds: (state) =>
				(state.orderInfo && state.orderInfo.classInfo) || null,
			discountInfo: (state) =>
				(state.orderInfo && state.orderInfo.loDiscountInfo) || {},
		}),
		getLoUserInfo() {
			return this.orderInfo && this.orderInfo.loUserInfo;
		},
	},
	components: {
		CourseInfo,
		AddressInfo,
		JobInfo,
		PayType,
		InfoTable,
		Discount,
		ThroughPay,
	},
	mounted() {
		// this.orderInfo = this.$store.state.orderInfo || null
		// if(this.orderInfo) {
		//   this.courseInfo = this.orderInfo.loCourseInfo
		//   this.userInfo = this.orderInfo.loUserInfo
		//   this.jboInfo = this.orderInfo.loJboInfo
		//   this.flightIds = this.orderInfo.classInfo
		// }
		//返回刷新
		window.onload = function() {
			var url = window.location.href;
			var ps = url.split('#');
			try {
				if (ps[1] != 1) {
					url += '#1';
				} else {
					window.location = ps[0];
				}
			} catch (ex) {
				url += '#1';
			}
			window.location.replace(url);
		};
		// window.addEventListener('pageshow', function(e) {
		// //如果检测到页面是从“往返缓存”中读取的，刷新页面
		//   if (e.persisted) {
		//     window.location.reload();
		//   }
		// });
	},
	// activated () {
	//   // this.orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '')
	//   // this.courseInfo = this.orderInfo.loCourseInfo
	//   // this.userInfo = this.orderInfo.loUserInfo
	//   // this.jboInfo = this.orderInfo.loJboInfo
	// },
	methods: {
		infoOnClose(val) {
			this.showInfoDialog = false;
			if (val === 'confirm') {
				this.submitOrder();
			}
		},
		formCheck() {
			if (this.courseInfo.interviewFlag === 1) {
				if (!this.jboInfo) {
					this.$toast('请选择岗位');
					return false;
				}
			}
			if (
				this.courseInfo.whetherDisplayPersonalInfo === 1 &&
				!this.userInfo
			) {
				this.$toast('请填写报名信息');
				return false;
			}
			if (this.getLoUserInfo) {
				this.$store.dispatch('setOrderInfo', {
					...this.$store.state.orderInfo,
					loUserInfo: { ...this.userInfo, ...this.discountInfo },
				});
			}
			if (this.courseInfo.hasShopAddress === 1) {
				this.addressInfo = this.$refs.addressInfo.getAddress();
				if (!this.addressInfo) {
					this.$toast('请选择收货地址');
					return false;
				}
			}
			if (
				this.courseInfo.interviewFlag === 3 &&
				this.courseInfo.oldStudentsPreferential === 1
			) {
				if (!this.userInfo.studentsPreferential) {
					this.$toast('请选择是否老学员');
					return false;
				}
				if (
					this.userInfo.studentsPreferential === 1 &&
					!this.userInfo.field01
				) {
					this.$toast('请上传老学员证明');
					return false;
				}
			}
			this.payType = this.$refs.paytype.getPayWay();
			this.showInfoDialog = true;
		},
		submitOrder() {
			let courseInfo = this.courseInfo;
			let userInfo = this.userInfo;
			let jboInfo = this.jboInfo;
			let flightIds = this.flightIds;
			let payType = this.$refs.paytype.getPayWay();
			let throughPay = this.$store.state.orderInfo.throughPay;
			let userAddressManagementId;
			if (this.$refs.addressInfo) {
				userAddressManagementId = this.$refs.addressInfo.getId();
			}
			let params = {
				type: courseInfo.interviewFlag,
				payWay: payType,
				currentPrice: courseInfo.currentPrice,
				courseGroupId: courseInfo.id,
				courseGroupName: courseInfo.courseGroupName,
				jobTableId: jboInfo ? jboInfo.id : '',
				flightIds: flightIds,
			};
			// 如果需要收货地址
			if (courseInfo.hasShopAddress === 1 && userAddressManagementId) {
				params.userAddressManagementId = userAddressManagementId;
			}
			// 根据课程状态添加个人信息
			if (this.courseInfo.whetherDisplayPersonalInfo === 1) {
				if (courseInfo.interviewFlag === 1) {
					params.personalInfo = userInfo;
				} else {
					params.personalInfo = {
						realName: userInfo.realName,
						gender: userInfo.gender,
						phone: userInfo.phone,
						remark: userInfo.remark,
						freshStudent: userInfo.freshStudent,
					};
				}
				if (courseInfo.interviewFlag === 3) {
					params.personalInfo.school = userInfo.school;
					if (courseInfo.oldStudentsPreferential === 1) {
						params.personalInfo.field01 = userInfo.field01;
						params.personalInfo.studentsPreferential =
							userInfo.studentsPreferential;
					}
				}
			} else {
				params.personalInfo = {
					realName: '',
					gender: '',
					phone: '',
					remark: '',
				};
			}
			if (this.courseInfo?.throughPay === 1)
				params.throughPay = throughPay;

			this.createOrder(params);
		},
		async createOrder(params) {
			this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0,
			});
			let response = await createOrder(params);
			this.$toast.clear();
			if (+response.returnCode !== 10001) {
				this.$toast(response.returnMsg);
				return false;
			}
			// 清理本地缓存
			this.$store.dispatch('clearOrderInfo');
			this.$toast(response.returnMsg);
			this.$router.replace({
				path: `/course/signup/review?orderId=${response.data.id}`,
			});
		},
		editInfo() {
			this.$router.push({ path: '/course/signup/info' });
		},
	},
};
</script>

<style lang="less" scoped>
.signup-box {
	background: #f3f3f3;
	// margin-bottom:2.8rem;
	padding-bottom: 3rem;
	height: 100%;
}
.personInfo {
	// width:94%;
	padding: 0 3%;
	background: #fff;
	margin-top: 0.25rem;
}
.personInfo .title {
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	line-height: 0.5rem;
	padding: 0.2rem 0;
	border-bottom: 1px #f2f2f5 solid;
	display: flex;
}
.personInfo .title .red {
	color: #ff3f47;
	flex-grow: 2;
}
.personInfo .arrow {
	width: 0.15rem;
	height: 0.24rem;
	margin: 0.1rem 0;
}
.personInfo .arrow img {
	width: 100%;
}

.reviewbox {
	padding: 0.1rem 3%;
	background: #fff;
	// padding-top:0.2rem;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	// height:2rem;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.reviewbox p {
	font-size: 14px;
	color: #ff3f47;
	text-align: center;
	height: 0.7rem;
	line-height: 0.7rem;
	// padding: 0.2rem 0;
	font-weight: bold;
}
.reviewbox .reviewBtn {
	width: 100%;
	height: 0.78rem;
	line-height: 0.78rem;
	text-align: center;
	font-weight: bold;
	background: #ff3f47;
	color: #ffffff;
	border-radius: 10px;
	padding: 0;
	margin: 0.1rem 0;
}
</style>